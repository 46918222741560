import React from 'react';

import Link from './link';
import styles from './tags.module.scss';

export default ({ data }) => (
  <div className={styles.tags}>
    {data.map((item, key) => (
      <Tag {...item} key={key} />
    ))}
  </div>
)

const Tag = tag => (
  <Link
    to={`/blog/${tag.url || tag.node.url}`}
    className={styles.tags__item}
    activeClassName={styles.tags__itemActive}
  >
    {tag.name || tag.node.name}
  </Link>
)
