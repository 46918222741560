import React from 'react'
import Cta from './index.js'
import { StaticQuery, graphql } from 'gatsby'

const CtaListing = ({ allDatoCmsCallToAction }) =>
  allDatoCmsCallToAction.edges.map(({ node }) => (
    <Cta key={node.originalId} {...node} />
  ))

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allDatoCmsCallToAction {
          edges {
            node {
              originalId
              title
              note
              buttonText
              buttonLink
              justifyContent
              isWhiteCta
              isLarge
              background {
                fluid(maxWidth: 2560) {
                  ...GatsbyDatoCmsFluid
                }
                alt
                title
                url
              }
            }
          }
        }
      }
    `}
    render={data => <CtaListing {...data} {...props} />}
  />
)
