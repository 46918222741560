import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import removePTags from '../utils/remove-p-tags';
import Container from './container';
import gatsbyImageData from './gatsbyImageData';
import PageHeader from './pageHeader';
import styles from './subPageHeader.module.scss';
import Tags from './tags';

const Header = ({
  datoCmsPageHeader,
  title,
  text,
  tags,
  background,
  currentUrl,
  children,
  variant,
}) => (
  <div
    className={styles.subPageHeader + (' ' + styles['subPageHeader' + variant])}
  >
    <Img
      className={styles.subPageHeader__background}
      fluid={{
        ...gatsbyImageData({
          image: background || datoCmsPageHeader.subpageHeaderBackground,
          sizes: {
            mobile: `100vw`,
            tablet: `100vw`,
            desktop: `100vw`,
          },
          customOptions: '&crop=entropy',
          srcSet: [
            {
              w: '360',
              h: variant && variant === 'Pricing' ? '2000' : '305',
            },
            {
              w: '480',
              h: variant && variant === 'Pricing' ? '2000' : '460',
            },
            {
              w: '640',
              h: variant && variant === 'Pricing' ? '2000' : '614',
            },
            {
              w: '768',
              h: variant && variant === 'Pricing' ? '2000' : '740',
            },
            {
              w: '800',
              h: variant && variant === 'Pricing' ? '2000' : '766',
            },
            {
              w: '945',
              h: variant && variant === 'Pricing' ? '2000' : '907',
            },
            {
              w: '1280',
              h: variant && variant === 'Pricing' ? '1600' : '680',
            },
            {
              w: '1500',
              h: variant && variant === 'Pricing' ? '1600' : '680',
            },
            {
              w: '1920',
              h: variant && variant === 'Pricing' ? '1600' : '680',
            },
            {
              w: '2560',
              h: variant && variant === 'Pricing' ? '1600' : '680',
            },
          ],
        }),
        critical: true,
        loading: 'eager',
      }}
      style={{ display: '', position: '' }}
      alt="Gym Radio"
      Tag="figure"
      imgStyle={{ objectPosition: '', objectFit: '' }}
      critical={true}
    />
    <PageHeader currentUrl={currentUrl} />
    <Container>
      {(title || children) && (
        <BottomSection title={title} text={text} tags={tags}>
          {children}
        </BottomSection>
      )}
    </Container>
  </div>
)

const BottomSection = ({ title, tags, text, children }) => (
  <div className={styles.subPageHeader__bottom}>
    <h1
      className={styles.subPageHeader__bottom__title}
      dangerouslySetInnerHTML={{
        __html: removePTags(title),
      }}
    />
    {tags && tags.length > 0 && <Tags data={tags} />}
    {text && text !== '' && (
      <div
        className={styles.subPageHeader__bottom__text}
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    )}
    {children}
  </div>
)

export default props => (
  <StaticQuery
    query={graphql`
      query {
        datoCmsPageHeader {
          subpageHeaderBackground {
            fluid(maxWidth: 2560) {
              ...GatsbyDatoCmsFluid
            }
            alt
            url
          }
        }
      }
    `}
    render={data => <Header {...data} {...props} />}
  />
)
