import { graphql } from 'gatsby';
import React from 'react';

import Blog from '../components/blog/index.js';
import ContentSection from '../components/contentSection';
import Cta from '../components/cta/listing.js';
import Layout from '../components/layout';
import PageFooter from '../components/sectionFooter';
import Seo from '../components/seo';
import SubPageHeader from '../components/subPageHeader';

export default ({ data, ...rest }) => {
  const posts = data.allDatoCmsBlogPost.edges
  const tags = [
    {
      node: {
        name: 'All',
        url: '',
      },
    },
    ...data.allDatoCmsBlogTag.edges,
  ]

  return (
    <Layout>
      <Seo pathname={rest.location.pathname} canonical="/blog" />
      <SubPageHeader title="Blog" tags={tags} />

      <ContentSection>
        <Blog data={posts} hLevel="2" />
      </ContentSection>

      <Cta />
      <PageFooter />
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    allDatoCmsBlogPost(
      filter: {
        originalId: { ne: "969745" }
        tags: { elemMatch: { id: { eq: $id } } }
      }
      sort: { fields: meta___createdAt, order: DESC }
    ) {
      edges {
        node {
          originalId
          title
          url
          excerpt
          featuredImage {
            fluid(maxWidth: 720) {
              ...GatsbyDatoCmsFluid
            }
            alt
            url
          }
          tags {
            url
          }
          treeParent {
            url
            treeParent {
              url
              treeParent {
                url
                treeParent {
                  url
                }
              }
            }
          }
        }
      }
    }

    allDatoCmsBlogTag {
      edges {
        node {
          name
          url
        }
      }
    }
  }
`
