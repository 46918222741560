import React from 'react'
import Container from './container'
import styles from './contentSection.module.scss'
import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby'

const ContentSection = ({ children, image }) => {
  return (
    <section className={styles.contentSection}>
      <Background image={image} />
      <div className={styles.contentSection__inner}>
        <Container>{children}</Container>
      </div>
    </section>
  )
}

const Background = ({ image }) => {
  const imageWithCustomSizes = {
    ...image,
    sizes: `(min-width: ${960 / 16}em) ${2560 / 16}em, calc(100vw * 0.5 * ${
      image.aspectRatio
    })`,
  }

  return (
    <div className={styles.contentSection__background__wrapper}>
      <Img
        fluid={imageWithCustomSizes}
        alt={'Gymradio'}
        Tag="figure"
        className={styles.contentSection__background}
        style={{ position: '' }}
        imgStyle={{ objectFit: '', objectPosition: '' }}
        critical={true}
      />
    </div>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        imageSharp(original: { src: { regex: "/division-empty/" } }) {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    `}
    render={data => <ContentSection image={data.imageSharp.fluid} {...props} />}
  />
)
